import type { ContentfulClientApi } from 'contentful'
import { createClient } from 'contentful'

const {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST,
} = process.env

export default function contentfulClient(): ContentfulClientApi<undefined> {
  // The client is initialized dynamically during the request, and not executed globally at build phase.
  return createClient({
    accessToken: CONTENTFUL_ACCESS_TOKEN || '',
    space: CONTENTFUL_SPACE_ID || '',
    host: CONTENTFUL_HOST,
    environment: CONTENTFUL_ENVIRONMENT,
  })
}
