import { CONTENT_ENTRIES } from '@/contentful/constants'
import type {
  CardSchema,
  GridSchema,
  LinkSchema,
} from '@/contentful/contentful-schema-types'
import { getModal } from '@/contentful/shared'
import type {
  TypeCardSkeleton,
  TypeRelatedBenefitsSkeleton,
  TypeVideoSkeleton,
} from '@/contentful/types'
import type { Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { cva } from 'class-variance-authority'
import type { Entry } from 'contentful'

import { cn, exists, hasMemcoTag } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Link, linkVariants } from '@/components/ui/link'
import {
  BenefitsCard,
  BenefitsCardAction,
  BenefitsCardContent,
  BenefitsCardDescription,
  BenefitsCardImage,
  BenefitsCardTextContent,
  BenefitsCardTitle,
  BenefitsCardTop,
} from '@/components/benefits-card'
import {
  CategoryCard,
  CategoryCardImage,
  CategoryCardImageLink,
} from '@/components/category-card'
import { ContentfulImage } from '@/components/contentful-image'
import {
  FeaturedCard,
  FeaturedCardAction,
  FeaturedCardContent,
  FeaturedCardDescription,
  FeaturedCardImage,
  FeaturedCardTextContent,
  FeaturedCardTitle,
} from '@/components/featured-card'
import {
  StandardCard,
  StandardCardAction,
  StandardCardContent,
  StandardCardDescription,
  StandardCardEyebrow,
  StandardCardTitle,
} from '@/components/standard-card'
import { MemcoModalWrapper } from '@/app/(marketing)/[[...slug]]/_components/memco-modal'
import { getLaunchDarklyFlags } from '@/app/launch-darkly/actions'
import { MEMCO_FLAG } from '@/app/launch-darkly/constants'

import { getVendorSlug, memcoStatusAction } from '../../actions'
import { VideoCardPaginatedGrid } from './video-card-paginated-grid'

type TypeCardEntry = Entry<
  TypeCardSkeleton,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>

type TypeRelatedBenefitsEntry = Entry<
  TypeRelatedBenefitsSkeleton,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>

type TypeVideoEntry = Entry<
  TypeVideoSkeleton,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>

type GridProps = {
  content: GridSchema
}

const gridCols = [
  'lg:grid-cols-1',
  'lg:grid-cols-2',
  'lg:grid-cols-3',
  'lg:grid-cols-4',
  'lg:grid-cols-5',
  'lg:grid-cols-6',
] as const

export const gridVariants = cva(
  'grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-x-4 md:gap-y-7',
  {
    variants: {
      layout: {
        standard: 'lg:gap-8',
        benefit: 'lg:grid-cols-3 lg:gap-x-8 lg:gap-y-7',
        featured: 'lg:grid-cols-1',
        category: 'lg:grid-cols-4',
        stat: 'lg:gap-8',
      },
    },
    defaultVariants: {
      layout: 'standard',
    },
  }
)

interface CtaButtonProps {
  cta: LinkSchema
}

function StandardCtaLink({ cta }: CtaButtonProps) {
  const {
    fields: { name = '', href = '' },
  } = cta

  return (
    <Button asChild variant="secondary-link">
      <Link aria-hidden="true" href={href}>
        {name}
      </Link>
    </Button>
  )
}

const StandardCardGrid = ({ content }: GridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeCardEntry[]

  const columnClass = gridCols[Math.min(blocks.length || 0, 6) - 1]

  return (
    <div className={cn(gridVariants({ layout: 'standard' }), columnClass)}>
      {blocks.map(({ fields, sys }) => (
        <StandardCard
          key={sys.id}
          variant={
            fields.backgroundColor === 'Light Olive' ? 'light-olive' : 'white'
          }
        >
          <StandardCardContent>
            {fields.eyebrow && (
              <StandardCardEyebrow>{fields.eyebrow}</StandardCardEyebrow>
            )}
            <StandardCardTitle>{fields.headline}</StandardCardTitle>
            <StandardCardDescription>
              {fields.content &&
                documentToReactComponents(fields.content, renderOptions)}
            </StandardCardDescription>
            <StandardCardAction>
              {fields.cta && <StandardCtaLink cta={fields.cta as LinkSchema} />}
            </StandardCardAction>
          </StandardCardContent>
        </StandardCard>
      ))}
    </div>
  )
}

const StatCardGrid = ({ content }: GridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeCardEntry[]

  const columnClass = gridCols[Math.min(blocks?.length, 6) - 1]

  return (
    <div className={cn(gridVariants({ layout: 'stat' }), columnClass)}>
      {blocks.map(({ fields }) => (
        <StandardCard
          key={fields.headline}
          variant={
            fields.backgroundColor === 'Light Olive' ? 'light-olive' : 'white'
          }
        >
          <StandardCardContent className="items-center">
            {fields.eyebrow && (
              <StandardCardEyebrow>{fields.eyebrow}</StandardCardEyebrow>
            )}
            <StandardCardTitle className="font-lyon text-7xl font-normal md:text-11xl lg:text-12xl">
              {fields.headline}
            </StandardCardTitle>
            <StandardCardDescription className="text-center">
              {fields.content &&
                documentToReactComponents(fields.content, renderOptions)}
            </StandardCardDescription>
            <StandardCardAction>
              {fields.cta && <StandardCtaLink cta={fields.cta as LinkSchema} />}
            </StandardCardAction>
          </StandardCardContent>
        </StandardCard>
      ))}
    </div>
  )
}

const CategoryCardGrid = ({ content }: GridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeCardEntry[]

  return (
    <div className={cn(gridVariants({ layout: 'category' }))}>
      {blocks.map(({ fields }) => (
        <div key={fields.headline} className="max-w-72 md:max-w-80">
          <CategoryCard>
            {fields.image && (
              <CategoryCardImage
                src={`https://${fields.image?.fields?.file?.url}`}
                alt={
                  (fields.image?.fields?.description as string) ||
                  (fields.image?.fields.title as string)
                }
              />
            )}
            <CategoryCardImageLink href="#">
              {fields.headline}
            </CategoryCardImageLink>
          </CategoryCard>
        </div>
      ))}
    </div>
  )
}

const BenefitCardGrid = async ({ content }: GridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeRelatedBenefitsEntry[]

  const cardsData = await Promise.all(
    blocks.map(async ({ fields }) => {
      const { vendor, cta } = fields
      const vendorSlug = await getVendorSlug(vendor)
      const href = vendorSlug ?? cta?.fields.href ?? ''
      return { fields, href }
    })
  )

  return (
    <div className={cn(gridVariants({ layout: 'benefit' }))}>
      {cardsData.map(({ fields, href }) => (
        <BenefitsCard key={fields.headline}>
          <BenefitsCardContent>
            <BenefitsCardTop>
              <BenefitsCardTextContent>
                <BenefitsCardTitle>{fields.headline}</BenefitsCardTitle>
                <BenefitsCardDescription>
                  {fields.content &&
                    documentToReactComponents(fields.content, renderOptions)}
                </BenefitsCardDescription>
              </BenefitsCardTextContent>
            </BenefitsCardTop>
            {fields.image && (
              <Link
                href={href}
                className={cn(
                  {
                    'pointer-events-none': !href,
                  },
                  linkVariants({ variant: 'default' })
                )}
              >
                <BenefitsCardImage
                  src={`https://${fields.image.fields.file!.url}`}
                  alt={
                    (fields.image.fields.description ||
                      fields.image.fields.title)!
                  }
                />
              </Link>
            )}
            <BenefitsCardAction>
              {fields.cta && (
                <Button
                  asChild
                  variant="secondary-link"
                  className={cn({
                    'pointer-events-none': !href,
                  })}
                >
                  <Link href={href} aria-hidden="true">
                    Learn more
                  </Link>
                </Button>
              )}
            </BenefitsCardAction>
          </BenefitsCardContent>
        </BenefitsCard>
      ))}
    </div>
  )
}

async function MemcoCtaButton({ cta }: CtaButtonProps) {
  const { name } = cta.fields

  if (!name) return null

  const memcoStatus = await memcoStatusAction()

  if (memcoStatus?.data) return <CtaButton cta={cta} />

  try {
    const modalContent = await getModal(
      CONTENT_ENTRIES.JOIN_MEMCO_MODAL_PELOTON_CARD
    )

    return <MemcoModalWrapper content={modalContent} />
  } catch (e) {
    console.error(e)
    return null
  }
}

async function CtaButton({ cta }: CtaButtonProps) {
  const { href, name } = cta.fields

  if (!href || !name) return null

  return (
    <Button asChild variant="secondary-link">
      <Link aria-hidden="true" href={href}>
        {name}
      </Link>
    </Button>
  )
}

interface CardItemProps {
  card: CardSchema
}

interface FeaturedCardItemProps extends CardItemProps {
  ctaButtonComponent?: typeof CtaButton
}

const MemcoCardItem = async ({ card }: CardItemProps) => {
  return <FeaturedCardItem card={card} ctaButtonComponent={MemcoCtaButton} />
}

const FeaturedCardItem = async ({
  card,
  ctaButtonComponent: CtaButtonComponent = CtaButton,
}: FeaturedCardItemProps) => {
  const { fields, metadata } = card

  if (
    hasMemcoTag(metadata) &&
    !(await getLaunchDarklyFlags([MEMCO_FLAG]))[MEMCO_FLAG]
  ) {
    return null
  }

  return (
    <div id={fields.id} className="col-span-full">
      <FeaturedCard reverse={fields.imagePositionRight}>
        <FeaturedCardImage src={fields.image?.fields?.file?.url} alt="" />
        <FeaturedCardContent>
          <FeaturedCardTextContent>
            <FeaturedCardTitle as="h3">{fields.headline}</FeaturedCardTitle>
            <FeaturedCardDescription>
              {documentToReactComponents(
                fields.content as Document,
                featuredCardRenderOptions
              )}
            </FeaturedCardDescription>
          </FeaturedCardTextContent>
          <FeaturedCardAction>
            {fields.cta && (
              <CtaButtonComponent cta={fields.cta as LinkSchema} />
            )}
          </FeaturedCardAction>
        </FeaturedCardContent>
      </FeaturedCard>
    </div>
  )
}

interface FeaturedCardGridProps extends GridProps {
  cardComponent?: typeof FeaturedCardItem
}

const FeaturedCardGrid = async ({
  content,
  cardComponent: CardComponent = FeaturedCardItem,
}: FeaturedCardGridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeCardEntry[]

  return (
    <div
      id={content.fields.id}
      className={cn(gridVariants({ layout: 'featured' }))}
    >
      {blocks.map((card) => (
        <CardComponent card={card} key={card.sys.id} />
      ))}
    </div>
  )
}

const MemcoFeaturedCardGrid = async ({ content }: GridProps) => {
  return <FeaturedCardGrid content={content} cardComponent={MemcoCardItem} />
}

const VideoCardGrid = ({ content }: GridProps) => {
  const blocks = (content.fields.blocks?.filter(exists) ||
    []) as TypeVideoEntry[]

  return <VideoCardPaginatedGrid blocks={blocks} />
}

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const asset = node.data.target.fields
      return (
        <div className="not-prose relative -mb-4 flex h-32 md:h-52 lg:mb-2">
          <ContentfulImage src={asset.file.url} alt={asset.description} fill />
        </div>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node?.data?.uri}>{children}</Link>
    ),
  },
}

const featuredCardRenderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) =>
      children != '' ? <p className="body-lg-regular">{children}</p> : null,
    [INLINES.HYPERLINK]: ({ data: { uri } }, children) => (
      <Link
        href={uri}
        className="text-2xl font-bold text-hudson-navy-700 underline"
      >
        {children}
      </Link>
    ),
    [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: asset } }) => (
      <ContentfulImage
        alt={asset?.fields?.description || ''}
        width={220}
        height={33}
        className="object-cover"
        src={asset?.fields?.file?.url || ''}
      />
    ),
  },
}

export const Grid = async ({ content }: GridProps) => {
  const GridComponent = {
    'Headline & Paragraph/Bulletpoints': StandardCardGrid,
    Stat: StatCardGrid,
    Category: CategoryCardGrid,
    Benefit: BenefitCardGrid,
    Featured: FeaturedCardGrid,
    Offer: MemcoFeaturedCardGrid,
    Video: VideoCardGrid,
  }[content.fields.variant]

  return <GridComponent content={content} />
}
