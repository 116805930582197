/*
 * This is the component that automatically scrolls to the specific text on he page based on URL hash.
 * The matching process is case-insensitive.
 * Example: If url has /privacy-policy#Notice-of-financial-incentives then this utility checks Notice-of-financial-incentives on the page and scroll to that element.
 */

'use client'

import type React from 'react'
import { useEffect } from 'react'

const ScrollHandler: React.FC = () => {
  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash.substring(1) // Get the hash without the '#'
      if (!hash) return
      const normalizedHash = hash.replace(/-/g, ' ').toLowerCase().trim() // Replace hyphen with spaces and trim it.

      // Function to find the first element containing the target text
      const findElementByText = (searchText: string): HTMLElement | null => {
        const elements = Array.from(document.body.querySelectorAll('*'))
        for (const el of elements) {
          if (el.textContent?.toLowerCase().trim() === searchText) {
            return el as HTMLElement
          }
        }
        return null
      }

      const targetElement = findElementByText(normalizedHash)

      if (targetElement) {
        // Scroll smoothly to the found element
        targetElement.scrollIntoView({ behavior: 'smooth' })
        // Remove hash after scroll
        window.history.replaceState(null, '', window.location.pathname)
      } else {
        console.warn(`Element with text "${hash}" not found.`)
      }
    }

    // Initial scroll when the component mounts
    scrollToHash()

    // Re-run when the URL hash changes
    window.addEventListener('hashchange', scrollToHash)

    return () => {
      window.removeEventListener('hashchange', scrollToHash)
    }
  }, [])

  return null
}

export default ScrollHandler
