import React from 'react'

import { cn } from '@/lib/utils'

import { ContentfulImage } from './contentful-image'

interface FeaturedCardProps extends React.HTMLAttributes<HTMLDivElement> {
  reverse?: boolean
}

const FeaturedCard = React.forwardRef<HTMLDivElement, FeaturedCardProps>(
  ({ className, children, reverse = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex size-full flex-col bg-card-quinary',
        'gap-7 rounded-none px-5 py-6',
        'md:gap-9 md:rounded-xl md:px-8 md:py-7',
        reverse ? 'lg:flex-row-reverse' : 'lg:flex-row',
        'lg:gap-10 lg:px-8 lg:py-7',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
)
FeaturedCard.displayName = 'FeaturedCard'

interface FeaturedCardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string
  alt: string
}

const FeaturedCardImage = React.forwardRef<
  HTMLDivElement,
  FeaturedCardImageProps
>(({ className, src = '/happy-man-smiling.jpeg', alt, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'relative aspect-video size-full overflow-hidden rounded-xl',
      'lg:w-1/2',
      className
    )}
    {...props}
  >
    <ContentfulImage
      src={src}
      alt={alt}
      fill
      className="object-cover"
      sizes="(max-width: 375px) 100vw, (max-width: 768px) 50vw, 33vw"
    />
  </div>
))
FeaturedCardImage.displayName = 'FeaturedCardImage'

const FeaturedCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex w-full flex-col items-start justify-center gap-8',
      'lg:w-1/2',
      className
    )}
    {...props}
  >
    {children}
  </div>
))
FeaturedCardContent.displayName = 'FeaturedCardContent'

const FeaturedCardTextContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-3', className)} {...props}>
    {children}
  </div>
))
FeaturedCardTextContent.displayName = 'FeaturedCardTextContent'

const FeaturedCardTitle = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & {
    as?: React.ElementType
  }
>(({ className, as: Component = 'h3', children, ...props }, ref) => (
  <Component
    ref={ref}
    className={cn('heading-md-bold text-card-foreground', className)}
    {...props}
  >
    {children}
  </Component>
))
FeaturedCardTitle.displayName = 'FeaturedCardTitle'

const FeaturedCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'body-lg-regular flex flex-col gap-3 text-card-foreground',
      className
    )}
    {...props}
  >
    {children}
  </div>
))
FeaturedCardDescription.displayName = 'FeaturedCardDescription'

const FeaturedCardAction = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('', className)} {...props}>
    {children}
  </div>
))
FeaturedCardAction.displayName = 'FeaturedCardAction'

export {
  FeaturedCard,
  FeaturedCardAction,
  FeaturedCardContent,
  FeaturedCardDescription,
  FeaturedCardImage,
  FeaturedCardTextContent,
  FeaturedCardTitle,
}
