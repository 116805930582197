import type {
  FooterSchema,
  ModalSchema,
  PageSchema,
  TaxonomyManagerSchema,
} from '@/contentful/contentful-schema-types'

import { exists } from '@/lib/utils'

import contentfulClient from './contentful-client'
import type {
  TypeFooterSkeleton,
  TypeModalSkeleton,
  TypeTaxonomyManagerSkeleton,
} from './types'

export type PageType = Pick<PageSchema['fields'], 'type'>['type']
export type PageVendor = Pick<PageSchema['fields'], 'vendor'>['vendor']

export async function fetchFooterByName({
  entryName,
}: {
  entryName: FooterSchema['fields']['name']
}): Promise<FooterSchema> {
  try {
    const { items } = await contentfulClient().getEntries<TypeFooterSkeleton>({
      content_type: 'footer',
      'fields.name': entryName,
    })

    return items[0] as FooterSchema
  } catch {
    throw new Error('Failed to fetch footer')
  }
}

export async function getModal(name: string) {
  try {
    const { items } = await contentfulClient().getEntries<TypeModalSkeleton>({
      content_type: 'modal',
      'fields.internalName': name,
      include: 3,
    })

    if (items.length === 0) throw new Error(`No content found for '${name}'`)

    return items[0] as ModalSchema
  } catch (e) {
    throw new Error(`Failed to fetch modal content: ${e.message}`)
  }
}

export async function fetchFooterByTag(tags: string[]): Promise<FooterSchema> {
  try {
    const { items } = await contentfulClient().getEntries<TypeFooterSkeleton>({
      content_type: 'footer',
      'metadata.tags.sys.id[in]': tags,
      select: ['fields'],
      include: 3,
    })

    return items[0] as FooterSchema
  } catch {
    throw new Error('Failed to fetch footer')
  }
}

export async function fetchTaxonomyManager(): Promise<TaxonomyManagerSchema> {
  try {
    const { items } =
      await contentfulClient().getEntries<TypeTaxonomyManagerSkeleton>({
        content_type: 'taxonomyManager',
        'fields.internalName': 'taxonomy-manager',
        include: 3,
      })
    return items[0] as TaxonomyManagerSchema
  } catch {
    throw new Error('Failed to fetch taxonomy manager')
  }
}

export async function fetchCategories() {
  try {
    return (
      (await fetchTaxonomyManager()).fields.categories?.filter(exists) || []
    )
  } catch (err) {
    console.error(err)
    return []
  }
}
